import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`startups`}</h1>
    <div className={styles.fadeInChildren}>
      <p>{`List of startups I have worked at in reverse chronological order:`}</p>
      <p><a parentName="p" {...{
          "href": "/startup/verishop"
        }}>{`Verishop`}</a></p>
      <p><a parentName="p" {...{
          "href": "/startup/cadre"
        }}>{`Cadre`}</a></p>
      <p><a parentName="p" {...{
          "href": "/startup/gigwax"
        }}>{`Gigwax`}</a></p>
      <p><a parentName="p" {...{
          "href": "/startup/pradux"
        }}>{`Pradux`}</a></p>
      <p><a parentName="p" {...{
          "href": "/startup/freecause"
        }}>{`FreeCause`}</a></p>
      <p><a parentName="p" {...{
          "href": "/startup/sensicast"
        }}>{`Sensicast`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      